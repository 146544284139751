<template>
  <div v-show="loading" class="home">
    <!--弹窗-->
    <van-popup  v-model="popupShow" @closed="closed" position="bottom" round > 
      <!--地区选择-->
      <van-picker show-toolbar :columns="columns" title="选择配送方式" @cancel="onCancel" @confirm="onConfirm" />
    </van-popup>
  </div>
</template>
 
<script>
export default {
  props: {
    columns: {
      type: Array,
    },
  },
  components: {},
  data() {
    return {
      popupShow: true,
      // customFieldName
      loading: true,

    };
  },

  created() {
    this.getList();
  },

  methods: {
    closed() {
      this.$emit("closed", false);
    },
    getList() {
      this.loading = true;
    },
    //递归拼装地区级联数据
    changeList(id) {
      let list = [];
      for (let i in this.regionList) {
        let region = this.regionList[i];
        if (region.regionPid == id) {
          let l = this.changeList(region.regionId);
          console.log(l)
          region.cities = l;

          list.push(region);
        }
      }

      return list;
    },
    onCancel() {
      //点取消，关闭弹窗
      this.popupShow = false;
      this.$emit('TypeCancel')
    },
    onConfirm(value) {
      //点确定，value为选择三级数据
      this.popupShow = false;
      this.$emit('TypeConfirm', value)
    },
  }
}
</script>
<style lang='scss' scoped>
::v-deep .van-picker__toolbar {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    position: relative;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    height: 11.733vw;
    margin-bottom: -2rem;
    background-color: white;
    z-index: 9999;
}
</style>