<template>
  <div class="spec-list-wrapper">
    <div class="spec-item-wrapper">
      <div class="spec-item"
        :class="item.id === selectedSpecData.id ? Number(item.status) !== 3 && Number(item.status) !== 2 ? 'spec-item-selected' : 'spec-item-cantselected' : 'spec-item'"
        v-for="item in normalList" :key="item.id" @click="() => handleSpecItemClick(item)">
        <div class="spec-item-content">
          <div class="spec-item-img-text">
            <img :src="item.coverImage" />
            <div class="spec-item-describe">{{ item.specifications }}</div>
          </div>
          <div class="spec-item-price">{{ item.showPrice }}点</div>
        </div>
        <span class="right-top-tip" v-if="Number(item.status) === 3">补货中</span>
        <span class="right-top-tip" v-if="Number(item.status) === 2">已下架</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SpecListChoose",
  props: {
    list: {
      type: Array
    }
  },
  data() {
    return {
      selectedSpecData: {}
    };
  },
  computed: {
    normalList() {
      return this.list.filter((item) => Number(item.status) !== 10);
    }
  },
  watch: {
    normalList: {
      handler: function (newVal) {
        this.setSelectedSpecData(newVal, "loadAfter");
      },
      immediate: true
    }
  },
  methods: {
    handleSpecItemClick(record) {
      this.setSelectedSpecData(record, "change");
    },
    setSelectedSpecData(record, emitName) {
      let specData = null;
      if (!record) return;
      if (Array.isArray(record) && record.length > 0) {
        specData = record[0];
      } else {
        specData = record;
      }
      this.selectedSpecData = specData;
      this.$emit(emitName, specData);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
