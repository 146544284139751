<template>
  <div>
    <van-popup v-model="show" @closed="closed" position="bottom" round :style="{ minHeight: '35%', paddingTop: '1rem' }">
      <div v-for="(item, index) in addressList" :key="index" class="addressItem" @click="selectItem(item)">
        <div>
          <div style="display: flex; justify-content:space-between;">
            <div style="display: flex; flex-direction: row; align-items: center;">
              <span style="font-size: 1rem;font-weight: 600;"> {{ item.recipient }}</span>
              <span style="font-size: 1rem; margin-left: 1rem;margin-right: 1rem;font-weight: 600;">
                {{ getPhone(item.recipientMobile) }} </span>
              <span class="default" v-if="item.defaultFlag == '1'"> 默认</span>
            </div>
          </div>
          <div style="margin-top: 0.5rem;margin-bottom: 0.7rem;margin-right: 2rem;">
            <span>{{ item.addressCname }}{{ item.addressQname }}{{ item.addressPname
            }}</span>
            {{ item.address }} {{ item.houseNum }}</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Dialog } from "vant";
export default {
  name: "",
  props: {
    addressList: {
      type: Array,
    },
  },
  data() {
    return {
      selectIndex: 0,
      show: true,
      value: 1,
    };
  },
  components: {},
  methods: {
    closed() {
      this.$emit("closed", false);
    },
    // 购买
    buy() {
      // console.log(this.good);
      // 添加一个count属性
      if (localStorage.getItem("nickname")) {
        // this.$set(this.good, "count", this.value);
        let obj = {
          index: this.selectIndex,
          cont: this.value
        }
        this.$emit('seleceItem', obj)
      } else {
        Dialog.confirm({
          title: "未登录",
          message: "是否前去登陆",
        })
          .then(() => {
            this.$router.push("/Login");
          })
          .catch(() => {
            // on cancel
          });
      }
    },
    getPhone(phone) {
      return this.$utils.formPhone(phone);
    },
    selectItem(item) {
      this.$emit('seleceAddressItem', item)
    }
  },
  mounted() {
  },
  computed: {},
  watch: {},
};
</script>

<style lang='scss' scoped>
.addressItem {
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  margin-left: 0.8rem;
  margin-right: 0.8rem;
  background-color: white;
  padding: 0.5rem 0.8rem 0 0.8rem;
  border-bottom: 1px solid #fd6e7e;
}


.default {
  border-radius: 0.2rem;
  font-size: 0.8rem;
  background-color: red;
  color: white;
  padding-left: 0.2rem;
  padding-right: 0.2rem;

}
</style>